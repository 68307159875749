import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4×6\\@80% 1RM`}</p>
    <p>{`Strict Pullups 4×6 (weighted if possible)`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`10 Calorie Ski Erg`}</p>
    <p>{`15 Ring Rows`}</p>
    <p>{`20 Pushups`}</p>
    <p>{`25 KBS (53/35)`}</p>
    <p><strong parentName="p">{`*`}{`The Granite Games Throwdown in coming up Saturday, January 12th.  We
are sold out but if you aren’t competing come out anyway and cheer on
all our teams!  We will be closing after the 4:30 class on Friday to set
up.  If you can help out please let us know, it will be greatly
appreciated.`}</strong></p>
    <p><strong parentName="p">{`*`}{`CrossFit the Ville’s Barbell Club will be starting January 19th!  If
you’d like to improve your Clean & Jerk and Snatch these classes are for
you.  Classes will be offered Saturday’s (11:15) and Sunday’s (1:45)
each weekend after our CrossFit classes.  Email Daniel for more
information.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      